import loadable from "@loadable/component";
import { FeedResultFragment } from "@ihr-radioedit/inferno-webapi";
import { ILog } from "@ihr-radioedit/inferno-core";
import { CoastFeedItemType } from "../../lib/coastFeed";

const CoastFeedInTheNewsHomepageItem = loadable(() => import("./CoastFeedInTheNewsHomepageItem.component"));
const CoastFeedUpcomingShowItem = loadable(() => import("./CoastFeedUpcomingShowItem.component"));
const CoastFeedArticleItem = loadable(() => import("./CoastFeedArticleItem.component"));
const CoastFeedPhotoItem = loadable(() => import("./CoastFeedPhotoItem.component"));
const CoastFeedGuestItem = loadable(() => import("./CoastFeedGuestItem.component"));
const CoastFeedShowItem = loadable(() => import("./CoastFeedShowItem.component"));

const log = ILog.logger("Coast Feed Item");

interface CoastFeedItemProps {
  feedType: CoastFeedItemType;
  item: FeedResultFragment;
}

// TODO: add new item types here
export const CoastFeedItem = ({ item, feedType }: CoastFeedItemProps) => {
  switch (feedType) {
    case CoastFeedItemType.IN_THE_NEWS_HOMEPAGE:
      return <CoastFeedInTheNewsHomepageItem item={item} />;
    case CoastFeedItemType.ARTICLE:
      return <CoastFeedArticleItem item={item} />;
    case CoastFeedItemType.PHOTO:
      return <CoastFeedPhotoItem item={item} />;
    case CoastFeedItemType.UPCOMING_SHOWS:
      return <CoastFeedUpcomingShowItem item={item} />;
    case CoastFeedItemType.GUEST:
      return <CoastFeedGuestItem item={item} />;
    case CoastFeedItemType.ART_BELL_VAULT:
    case CoastFeedItemType.SHOW:
      return <CoastFeedShowItem item={item} type={feedType} />;
    default:
      log.debug(`No match for Coast feed item type: ${feedType}.`);
  }

  return null;
};

export default CoastFeedItem;
